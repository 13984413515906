// Here you can add other styles
.flex-item {
    flex-wrap: wrap;
  }
  .live-video {
    max-width: 100%;
    height: 100vh;
    margin: auto;
    position: relative;
  }
  .precall-live-video video{
    height: 100% !important;
    width: 100% !important;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .primary-button {
    background-color: #e50914;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    outline: 0 !important;
    border: 1px solid #e50914;
    box-shadow: 0px 15px 33px rgba(229, 9, 20, 0.15);
    border-radius: 100px;
    padding: 15px 78px 15px 78px;
    margin-top: 5px;
    font-family: "Mulish", sans-serif;
  }
  .primary-button:hover {
    box-shadow: none;
  }

  .secondary-button {
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    outline: 0 !important;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 15px 78px 15px 78px;
    margin-top: 5px;
    font-family: "Mulish", sans-serif;
  }
  .secondary-button:hover {
    box-shadow: none;
  }
  
  /* AUDIO TEST CSS */
  #mic_preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  #mic_preview .OT_publisher {
    display: none;
  }

  .OT_subscriber {
    width: 100% !important;
    height: 100vh !important;
  }
  
  .audio-indicator {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  #volume_level_meter {
    -moz-appearance: none;
    appearance: none;
    height: 10px;
    width: 100%;
    background: none;
  }
  
  meter {
    -webkit-writing-mode: horizontal-tb !important;
    appearance: auto;
    box-sizing: border-box;
    display: inline-block;
    height: 50px;
    width: 100%;
    vertical-align: -0.2em;
    -webkit-user-modify: read-only !important;
  }
  
  #volume_level_meter-ie {
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: whiteSmoke;
    box-shadow: 0 5px 5px -5px #333 inset;
    width: 90%;
    height: 25px;
    display: block;
    margin-left: 10px;
  }
  
  #volume_level_meter-ie > span {
    height: inherit;
    width: 0%;
    box-shadow: 0 5px 5px -5px #999 inset;
    background-color: blue;
    background-image: linear-gradient(90deg, green, darkgreen);
    background-size: 100% 100%;
    display: block;
    text-indent: -9999px;
  }
  
  /* END */
  
  .video-overlay {
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    padding: 15px;
  }
  .overlay-box {
    position: fixed;
    padding: 50px;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: #fff;
    text-align: left;
    z-index: 999999999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 92%;
  }
  .overlay-box .title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
  }
  .overlay-box .title h2 {
    font-size: 30px;
    line-height: 38px;
    margin: 0;
    font-family: "Uber Move Medium";
  }
  .overlay-box .model-close {
    color: #fff;
    opacity: 0.5;
  }
  .video-setting {
    max-width: 678px;
    /* min-height: 500px; */
  }
  .video-setting .item {
    border-bottom: 0.2px solid rgba(255, 255, 255, 0.7);
    padding: 15px 10px 13px 10px;
  }
  .video-setting .item h3 {
    font-family: "Uber Move Medium";
    margin: 0 0 3px 0px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.015em;
  }
  .video-setting .select-option select {
    background: url("../assets/icons/select-polygon.svg") right 4px no-repeat;
    padding-right: 18px;
    border: 0;
    color: #fff;
    outline: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .video-setting .select-option {
  }
  .video-setting .test {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: #ffffff;
  }
  .video-setting .test img {
    margin-right: 7px;
  }
  .video-setting .test a {
    color: #fff;
  }
  .video-content-wrap {
    position: relative;
    height: 100%;
    padding: 40px 60px 0 60px;
    margin: 0 auto 0 auto;
    border-radius: 5px;
    color: #fff;
    text-align: left;
  }
  .fixed-tip-img{
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }
  .fixed-img-container{
    width: 80%;
  }
  .tip-fixed-content-img {
    /* width: 13%;
    position: absolute;
    right: 0;
    top: 25%; */
    margin-left: 25%;
  }
  .tip-fixed-content {
    position: absolute;
    right: 30px;
    top: 55%;
  }
  .top-auctions {
    display: flex;
    flex-wrap: wrap;
  }
  .gray-btn-theme {
    border: 0;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    min-width: 100px;
    color: #fff;
    font-family: "Uber Move Medium";
    font-size: 18px;
    line-height: 23px;
    /* background: rgba(255, 255, 255, 0.15); */
    background: rgba(185, 185, 185, 0.45);
    /* box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.35); */
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 1);
    border-radius: 5px;
    font-family: "Mulish", sans-serif;
    text-shadow: 2px 2px 4px #4f4e4ebd;
  }
  .gray-btn-theme img {
    vertical-align: middle;
    margin-top: -2px;
    max-width: 20px;
  }
  button.gray-btn-theme {
    opacity: 0.6;
    cursor: pointer;
  }
  button.gray-btn-theme:hover {
    opacity: 1;
  }
  .top-auctions .align-left,
  .top-auctions .align-right {
    display: flex;
  }
  .top-auctions .align-right {
    margin-left: auto;
  }
  .align-left .gray-btn-theme {
    margin-right: 20px;
  }
  .align-right .gray-btn-theme {
    margin-left: 20px;
  }
  .icon-right-align {
    margin-left: 5px;
  }
  .icon-left-align {
    margin-right: 5px;
  }
  .live-comments {
    width: 100%;
    margin: 150px 0px 30px 0px;
  }
  .live-comments .comments-box {
    max-width: 375px;
    width: 100%;
    position: absolute;
    bottom: 50px;
  }
  .live-comments .user-comment {
    margin-bottom: 20px;
    padding-right: 15px;
    display: flex;
    color: #fff;
  }
  .user-comment .user-photo {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .user-detail {
    width: 70%;
  }
  .live-comments .user-detail .time {
    font-size: 10px;
    line-height: 13px;
    margin-left: 15px;
    font-family: "Mulish", sans-serif;
  }
  .live-comments .user-detail h5 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 3px;
  }
  .live-comments .user-detail p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
  .comments-scroll {
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 0.4)),
      to(rgba(255, 255, 255, 1))
    );
  }
  .like-auctions {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;
  }
  .like-auctions button {
    border: 0;
    background: transparent;
    margin-left: 15px;
    cursor: pointer;
  }
  .like-auctions img {
    cursor: pointer;
  }
  .input-user-comment {
    display: flex;
    position: relative;
  }
  .gif-div {
    content: "";
    width: 100%;
    height: 190px;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    left: 0;
    bottom: 50px;
    border-radius: 5px;
    z-index: 10;
    max-width: 380px;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .gif-div::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .gif-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  .gif-div::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    outline: 1px solid rgb(216, 216, 216);
  }
  .inner::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .inner::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  .inner::-webkit-scrollbar-thumb {
    background-color: rgb(65, 65, 65);
    outline: 1px solid rgb(65, 65, 65);
  }
  .input-user-comment .input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 45px;
    flex: 0 0 380px;
    max-width: 380px;
    width: 100%;
  }
  .input-user-comment .input-box input {
    border: 0;
    background: transparent;
    width: calc(100% - 50px);
    height: 44px;
    padding: 10px 10px;
    color: #fff;
  }
  .input-box input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  .input-user-comment .input-box button {
    border: 0;
    background: url("../assets/icons/icon-send.svg") center center no-repeat;
    width: 40px;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;
    cursor: pointer;
  }
  .upload-dialog {
    border: 0;
    text-align: center;
    width: 40px;
    height: 20px;
    font-size: 0;
    position: relative;
  }
  .upload-dialog input {
    width: 40px !important;
    height: 30px !important;
    opacity: 0;
    padding: 0 !important;
    position: absolute;
    left: 0;
    top: 16%;
    cursor: pointer;
  }
  .upload-dialog img {
    width: 55%;
  }
  .input-user-comment .like-auctions {
    margin-left: 40px;
  }
  .tip-recieved {
    background: #e50914;
    border-radius: 20px;
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    padding: 5px 10px;
  }
  .menu-comment-setting {
    display: none;
    position: absolute;
    right: 15px;
    top: 11px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: -4px 13px 25px rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    overflow: hidden;
  }
  .menu-comment-setting ul {
    list-style: none;
    padding-inline-start: 0px;
    margin-block-end: 0px;
    margin-block-start: 0px;
  }
  .menu-comment-setting li {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .menu-comment-setting li:first-child {
    border-top: 0;
  }
  .menu-comment-setting a {
    cursor: pointer;
    padding: 15px 22px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    display: block;
  }
  .menu-comment-setting.show {
    display: block;
  }
  .video-report-form {
    max-width: 700px;
  }
  .video-report-form .form-group {
    margin-bottom: 20px;
  }
  .video-report-form select,
  .video-report-form textarea {
    border: 0;
    background: rgba(134, 134, 134, 0.25);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    color: #fff;
  }
  .video-report-form textarea {
    height: 123px;
  }
  .video-report-form select {
    height: 50px;
    background-image: url("../assets/icons/arrow-ios-down.svg");
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .video-report-form .btn-wrap {
    margin-top: 30px;
  }
  
  .live-video video {
    height: 100% !important;
    width: 100% !important;
    padding: 0;
    margin: 0;
    border: 0;
  }
  .comments-scroll {
    max-height: 320px;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  .comments-scroll:hover {
    overflow-y: auto;
    --webkit-overflow-scrolling: touch;
  }
  .video-confirm-pop {
    max-width: 550px;
  }
  .video-confirm-pop h3 {
    text-align: center;
    font-size: 30px;
    line-height: 38px;
  }
  .video-confirm-pop .primary-button,
  .video-confirm-pop .secondary-button {
    padding: 10px;
    min-width: 180px;
    margin: 6px 10px;
  }
  
  .comments-scroll::-webkit-scrollbar {
    width: 2px;
  }
  
  .comments-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  
  .comments-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    outline: 1px solid rgb(216, 216, 216);
  }
  .back-to-home {
    width: 220px;
  }
  .tip-amt-form .title {
    display: block;
  }
  .tip-amt-form .title h2 {
    text-align: center;
    margin-top: 100px;
  }
  .tip-amt-form .text-right {
    text-align: right;
  }
  .default-payment {
    padding: 30px 15px 30px 15px;
    background: #272424;
    text-align: center;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .default-payment h5 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 10px 0px;
    letter-spacing: 0.015em;
    color: #ffffff;
  }
  .default-payment .acc-num {
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    border-radius: 5px;
  }
  .default-payment img {
    width: 50px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .btn-amt-items {
    display: flex;
    /* justify-content: space-between; */
  }
  .form-tip-amt input {
    background: #171414;
    opacity: 0.5;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border: 1px solid #ececec;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 300;
  }
  .form-tip-amt .btn-amt {
    margin-bottom: 5px;
    margin-right: 5px;
    justify-content: space-between;
    font-weight: 600;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #fff;
    padding: 18px 20px;
    background: #272424;
    opacity: 0.8;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    width: 24%;
  }
  .form-tip-amt .btn-amt:hover {
    opacity: 9;
  }
  .page-video .overlay {
    z-index: 10;
  }
  .tip-image {
    text-align: center;
    margin-bottom: -50px;
  }
  .tip-image img {
    width: 170px;
    height: 150px;
  }
  .heart {
    position: absolute;
    background: url("../assets/icons/img.png") no-repeat;
    background-position: left;
    background-size: 2900%;
    height: 90px;
    width: 90px;
    top: 50%;
    left: 21%;
    transform: translate(-50%, -50%);
  }
  .heart.heart-active {
    animation: animate 0.8s steps(28) 1;
    background-position: right;
  }
  @keyframes animate {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  
  /* Heart for like */
  div.hearts {
    cursor: default;
    width: 200px;
    height: 300px;
    position: absolute;
    bottom: 45px;
    left: 0;
    z-index: 1;
    margin-left: 0px;
  }
  div.heartanim {
    width: 25px;
    height: 30px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    display: none;
  }
  div.heartanim i {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999999;
    opacity: 1;
  }
  .colOne {
    color: red;
  }
  .colTwo {
    color: red;
  }
  .colThree {
    color: red;
  }
  .colFour {
    color: red;
  }
  .colFive {
    color: red;
  }
  .colSix {
    color: red;
  }
  @keyframes flowOne {
    0% {
      opacity: 1;
      bottom: 0;
      left: 14%;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
      left: 0;
    }
    60% {
      opacity: 1;
    }
    80% {
      bottom: 80%;
    }
    100% {
      opacity: 1;
      bottom: 100%;
      left: 18%;
    }
  }
  @keyframes flowTwo {
    0% {
      opacity: 1;
      bottom: 0;
      left: 0;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
      left: 11%;
    }
    60% {
      opacity: 1;
    }
    80% {
      bottom: 60%;
    }
    100% {
      opacity: 1;
      bottom: 80%;
      left: 0;
    }
  }
  @keyframes flowThree {
    0% {
      opacity: 1;
      bottom: 0;
      left: 0;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
      left: 30%;
    }
    60% {
      opacity: 1;
    }
    80% {
      bottom: 70%;
    }
    100% {
      opacity: 1;
      bottom: 90%;
      left: 0;
    }
  }
  
  .go-live {
    position: absolute;
    bottom: 0;
    left: 35%;
    right: 30%;
  }
  
  .gifUpload {
    width: 40px;
    height: 20px;
    cursor: pointer;
  }
  .report-event {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 70px;
    cursor: pointer;
  }
  .fullscreen-event {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 30px;
    cursor: pointer;
  }
  .deactivate-event {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 70px;
    cursor: pointer;
    margin-bottom: -10px;
  }
  .event-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 110;
  }
  .precalltest {
      height: 250px;
    border: #fff 1px solid;
  }
  
  .precalltestaudio {
    height: 150px;
  }

  .report-scroll {
    overflow-y: scroll;
  }
  .report-scroll::-webkit-scrollbar {
    width: 2px;
  }
  
  .report-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  
  .report-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(48, 47, 47);
    outline: 1px solid rgb(20, 20, 20);
  }

  .unblock-audio {
    top: 35%;
    left: 50%;
    right: 50%;
    position: absolute;
    z-index: 99999999;
  }

  .danger-red {
    color:red;
    font-size: 18px;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
  }
  
  /* end */
  
  @media (max-width: 991px) {
    .video-content-wrap {
      padding: 20px;
    }
  }
  @media (max-width: 767px) {
    .overlay-box {
      margin-top: 15px;
      padding: 30px 15px;
    }
    .overlay-box .title h2 {
      font-size: 24px;
      line-height: 28px;
    }
    .video-setting .item h3 {
      font-size: 20px;
      line-height: 25px;
    }
    .video-setting .item {
      padding: 10px 5px 10px 5px;
    }
    .video-content-wrap {
      padding: 0px;
    }
    .align-right .gray-btn-theme {
      margin-left: 10px;
    }
    .align-left .gray-btn-theme {
      margin-right: 10px;
    }
    .form-tip-amt .btn-amt {
      padding: 15px 10px;
      font-size: 18px;
      line-height: 22px;
    }
    .tip-amt-form .title h2 {
      margin-top: 30px;
    }
    .gray-btn-theme {
      font-size: 16px;
      min-width: auto;
    }
    .input-user-comment .input-box {
      flex: 0 0 90%;
    }
    .video-confirm-pop h3 {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 15px;
    }
    .video-confirm-pop h3 br {
      display: none;
    }
    .page-video .btn-wrap {
      margin-top: 20px;
    }
    .tip-fixed-content {
      right: 0px;
    }
    .tip-fixed-content-img {
      /* width: 30%; */
      /* top: 30%; */
    }
    .fixed-img-container{
      margin-left: auto;
    }
    .tip-image {
      margin-bottom: 0px;
    }
    .tip-image img {
      width: 150px;
    }
    .heart {
      height: 40px;
      width: 50px;
    }
    .input-user-comment .like-auctions {
      margin-left: 15px;
    }
    div.hearts {
      width: 30px;
      left: -10px;
    }
    .go-live {
      right: 0;
    }
    .report-event{
      right: 35px;
    }
    .fullscreen-event{
      right: 0px;
    }
    .gif-div {
      width: 90%;
    }
    .event-loader {
      left: 40%;
    }
  }
  
  @media (max-width: 576px) {
    .btn-amt {
      width: 31% !important;
    }
  }
  
  @media (max-width: 320px) {
    .tip-fixed-content-img {
      /* width: 25%; */
    }
    .fixed-img-container{
      width: 70%;
    }
  }
  